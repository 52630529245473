import { useState } from 'react'
import { Button, Text, Flex, Message, Modal, InjectedModalProps, Checkbox } from 'packages/uikit'
import { useExpertModeManager } from 'state/user/hooks'
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'
import { AbstradexText, AbstradexButton } from 'components/abstradex/components/ui'

interface ExpertModalProps extends InjectedModalProps {
  setShowConfirmExpertModal: (boolean) => void
  setShowExpertModeAcknowledgement: (boolean) => void
}

const ExpertModal: React.FC<ExpertModalProps> = ({ setShowConfirmExpertModal, setShowExpertModeAcknowledgement }) => {
  const [, toggleExpertMode] = useExpertModeManager()
  const [isRememberChecked, setIsRememberChecked] = useState(false)

  const { t } = useTranslation()

  return (
    <Modal
      title={t('Expert Mode')}
      onBack={() => setShowConfirmExpertModal(false)}
      onDismiss={() => setShowConfirmExpertModal(false)}
      headerBackground="gradients.cardHeader"
      style={{ maxWidth: '442px' }}
    >
      <ExpertWarning variant="warning" mb="12px">
        <AbstradexText.AbstradexMainText className="opacity-08">
          {t(
            "Expert mode turns off the 'Confirm' transaction prompt, and allows high slippage trades that often result in bad rates and lost funds.",
          )}
        </AbstradexText.AbstradexMainText>
      </ExpertWarning>
      <AbstradexText.AbstradexMainText className="opacity-08" fontSize="12px" mb="12px">
        {t('Only use this mode if you know what you’re doing.')}
      </AbstradexText.AbstradexMainText>
      <Flex alignItems="center" mb="12px">
        <ExpertCheckbox
          name="confirmed"
          type="checkbox"
          checked={isRememberChecked}
          onChange={() => setIsRememberChecked(!isRememberChecked)}
          scale="sm"
        />
        <AbstradexText.AbstradexMainText
          className="opacity-08"
          ml="10px"
          color="textSubtle"
          style={{ userSelect: 'none' }}
        >
          {t('Don’t show this again')}
        </AbstradexText.AbstradexMainText>
      </Flex>
      <AbstradexButton.AbstradexStyledActionButton
        mb="8px"
        id="confirm-expert-mode"
        onClick={() => {
          // eslint-disable-next-line no-alert
          if (window.prompt(`Please type the word "confirm" to enable expert mode.`) === 'confirm') {
            toggleExpertMode()
            setShowConfirmExpertModal(false)
            if (isRememberChecked) {
              setShowExpertModeAcknowledgement(false)
            }
          }
        }}
      >
        {t('Turn On Expert Mode')}
      </AbstradexButton.AbstradexStyledActionButton>
      <CancelButton
        variant="secondary"
        onClick={() => {
          setShowConfirmExpertModal(false)
        }}
      >
        {t('Cancel')}
      </CancelButton>
    </Modal>
  )
}

export default ExpertModal

const ExpertWarning = styled(Message)`
  border: none;
  border-radius: 8px;
  background-color: rgba(180, 180, 180, 0.66);
`

const CancelButton = styled(AbstradexButton.AbstradexStyledActionButton)`
  background-color: rgba(180, 180, 180, 0.66);
  color: ${({ theme }) => theme.colors.absWhiteColor};
`

const ExpertCheckbox = styled(Checkbox)`
  border-radius: 4px;

  &:hover:not(:disabled):not(:checked) {
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:checked {
    background-color: ${({ theme }) => theme.colors.absMainColor};

    &:after {
      border-color: ${({ theme }) => theme.colors.absBlackColor};
    }
  }
`
